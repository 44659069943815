import { useState } from "react";

import ModalMenu from "components/ModalMenu/ModalMenu";
import IconHeaderLogo from "../../assets/img/header-logo.png";

import styles from "./Header.module.scss";
import useWindowSize from "utils/use-window-size";

const Header = ({
  scrooltoServices,
  scrooltoAboutLawyer,
  scrooltoContacts,
}) => {
  const [isOpenModalMenu, setIsOpenModalMenu] = useState(false);

  const openModalMenu = () => setIsOpenModalMenu(true);
  const closeModalMenu = () => setIsOpenModalMenu(false);

  const { width } = useWindowSize();

  return (
    <>
      <div className={styles["header-wrapper"]}>
        <div className={styles["photo-img"]}></div>
        <div className={styles["header-menu"]}>
          <div className={styles["header-logo"]}>
            <a href="/">
              <img src={IconHeaderLogo} />
            </a>
          </div>
          {width && width > 768 ? (
            <div className={styles["menu"]}>
              <ul>
                <li onClick={() => scrooltoServices()}>Услуги</li>
                <li onClick={scrooltoAboutLawyer}>Об адвокате</li>
                <li onClick={scrooltoContacts}>Контакты</li>
              </ul>
            </div>
          ) : (
            <div className={styles["modal-menu"]} onClick={openModalMenu}>
              <i className="pi pi-align-justify"></i>
            </div>
          )}
        </div>
        <div className={styles["content"]}>
          <h1>Жорова Кристина Владимировна</h1>
          {width && width > 480 ? (
            <h3>
              Юридические услуги физическим и юридическим лицам. Мой основной
              принцип это профессионализм и индивидуальный подход к каждому
              доверителю. Для меня не важно, на чьей стороне сила; важно то, на
              чьей стороне право.
            </h3>
          ) : (
            <h3>Юридические услуги физическим и юридическим лицам.</h3>
          )}

          <div className={styles["btn-wrapper"]}>
            <button onClick={scrooltoContacts}>Кнопка для связи</button>
          </div>
        </div>
      </div>
      <ModalMenu
        visible={isOpenModalMenu}
        hide={closeModalMenu}
        scrooltoServices={scrooltoServices}
        scrooltoAboutLawyer={scrooltoAboutLawyer}
        scrooltoContacts={scrooltoContacts}
      />
    </>
  );
};

export default Header;
