import { IconDriveConsult } from "assets/icons/drive_consult";
import { IconConfidentiality } from "assets/icons/confidentiality";
import { IconIndividualApproach } from "assets/icons/individual_approach";

import styles from "./Experience.module.scss";

const Experience = () => {
  return (
    <div className={styles["experience-wrapper"]}>
      <span className={styles["title"]}>
        Более 10 лет опыта адвокатской деятельности
      </span>

      <div className={styles["advantages-list"]}>
        <div className={styles["advantage-item"]}>
          <IconDriveConsult />
          <span>Выездная консультация</span>
          <b>__________</b>
          <p>Возможность выезда на дом, на место ДТП, к месту работы</p>
        </div>
        <div className={styles["advantage-item"]}>
          <IconIndividualApproach />
          <span>Индивидуальный подход</span>
          <b>__________</b>
          <p>
            Каждый клиент для меня исключителен, каждое дело уникально и ведётся
            мною лично
          </p>
        </div>
        <div className={styles["advantage-item"]}>
          <IconConfidentiality />
          <span>Полная конфиденциальность</span>
          <b>__________</b>
          <p>Гарантия своим клиентам соблюдения адвокатской тайны</p>
        </div>
      </div>
    </div>
  );
};

export default Experience;
