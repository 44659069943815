import Cases from "../Cases/Cases";
import Footer from "../Footer/Footer";
import Contacts from "components/Contacts/Contacts";
import AboutLaw from "components/AboutLaw/AboutLaw";
import Separate from "components/Separate/Separate";
import Experience from "../Experience/Experience";

const MainPageLayout = ({
  services,
  aboutLawyer,
  contacts,
  scrooltoServices,
  scrooltoAboutLawyer,
  scrooltoContacts,
}) => {
  return (
    <>
      <Cases services={services} />
      <Experience />
      <AboutLaw aboutLawyer={aboutLawyer} />
      <Separate />
      <Contacts contacts={contacts} />
      <Footer
        scrooltoServices={scrooltoServices}
        scrooltoAboutLawyer={scrooltoAboutLawyer}
        scrooltoContacts={scrooltoContacts}
      />
    </>
  );
};

export default MainPageLayout;
