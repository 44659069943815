import MainPageLayout from "../components/MainPage/MainPage";

const MainPage = ({
  services,
  aboutLawyer,
  contacts,
  scrooltoServices,
  scrooltoAboutLawyer,
  scrooltoContacts,
}) => {
  return (
    <MainPageLayout
      services={services}
      aboutLawyer={aboutLawyer}
      contacts={contacts}
      scrooltoServices={scrooltoServices}
      scrooltoAboutLawyer={scrooltoAboutLawyer}
      scrooltoContacts={scrooltoContacts}
    />
  );
};

export default MainPage;
