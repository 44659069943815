export const IconHousingLaw = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="housing_law"
      data-name="Layer 3"
      viewBox="0 0 64 64"
    >
      <path d="M50,57H42V46h6a1,1,0,0,0,.563-1.826l-22-15a.994.994,0,0,0-1.126,0l-22,15A1,1,0,0,0,4,46h6V57H2a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H50a1,1,0,0,0,1-1V58A1,1,0,0,0,50,57ZM26,31.21,44.758,44H41.3L26.555,34.168a1,1,0,0,0-1.11,0L10.7,44H7.242ZM3,59H14v2H3Zm15,2H16V57h2Zm2-2H32v2H20Zm8-2H24V49h4Zm8,4H34V57h2Zm-3-6a1,1,0,0,0-1,1v1H30V48a1,1,0,0,0-1-1H23a1,1,0,0,0-1,1v9H20V56a1,1,0,0,0-1-1H15a1,1,0,0,0-1,1v1H12V45.535L26,36.2l14,9.333V57H38V56a1,1,0,0,0-1-1Zm16,6H38V59H49Z"></path>
      <path d="M44.646,17.232l.5-.5A3.417,3.417,0,0,0,48.879,16l1.587-1.587a3.414,3.414,0,0,0,0-4.827L42.879,2a3.5,3.5,0,0,0-4.828,0L36.465,3.586a3.417,3.417,0,0,0-.735,3.735l-7.472,7.472a3.544,3.544,0,0,0-3.691.722L22.981,17.1a3.414,3.414,0,0,0,0,4.828l7.585,7.585a3.415,3.415,0,0,0,4.83,0l1.585-1.586a3.393,3.393,0,0,0,.721-3.753l.53-.53L55.258,40.672a4.535,4.535,0,1,0,6.414-6.414ZM29.88,16l7.085-7.085L43.551,15.5l-7.086,7.085Zm8-11,1.587-1.587a1.415,1.415,0,0,1,2,0L49.05,11a1.415,1.415,0,0,1,0,2l-1.586,1.586a1.449,1.449,0,0,1-2,0L37.879,7a1.415,1.415,0,0,1,0-2ZM35.567,26.515,33.981,28.1a1.449,1.449,0,0,1-2,0L24.4,20.514a1.412,1.412,0,0,1,0-2l1.585-1.586a1.415,1.415,0,0,1,2,0l7.585,7.585a1.415,1.415,0,0,1,0,2Zm7.665-7.869L45.586,21,42,24.586l-2.354-2.354ZM60.258,39.258a2.54,2.54,0,0,1-3.586,0L43.414,26,47,22.414,60.258,35.672A2.54,2.54,0,0,1,60.258,39.258Z"></path>
      <path d="M33.952,44.306a1,1,0,0,0-.371-1.119l-7-5a1,1,0,0,0-1.162,0l-7,5A1,1,0,0,0,19,45H33A1,1,0,0,0,33.952,44.306ZM22.12,43,26,40.229,29.88,43Z"></path>
      <rect x="14" y="48" width="6" height="2"></rect>
      <rect x="32" y="48" width="6" height="2"></rect>
      <path d="M16,10H14a3,3,0,0,0,3,3v1h2V13a3,3,0,0,0,0-6V5a1,1,0,0,1,1,1h2a3,3,0,0,0-3-3V2H17V3a3,3,0,0,0,0,6v2A1,1,0,0,1,16,10Zm0-4a1,1,0,0,1,1-1V7A1,1,0,0,1,16,6Zm3,3a1,1,0,0,1,0,2Z"></path>
      <path d="M3,27H1a3,3,0,0,0,3,3v1H6V30a3,3,0,0,0,0-6V22a1,1,0,0,1,1,1H9a3,3,0,0,0-3-3V19H4v1a3,3,0,0,0,0,6v2A1,1,0,0,1,3,27Zm0-4a1,1,0,0,1,1-1v2A1,1,0,0,1,3,23Zm3,3a1,1,0,0,1,0,2Z"></path>
      <path d="M60,17V15a1,1,0,0,1,1,1h2a3,3,0,0,0-3-3V12H58v1a3,3,0,0,0,0,6v2a1,1,0,0,1-1-1H55a3,3,0,0,0,3,3v1h2V23a3,3,0,0,0,0-6Zm-3-1a1,1,0,0,1,1-1v2A1,1,0,0,1,57,16Zm3,5V19a1,1,0,0,1,0,2Z"></path>
    </svg>
  );
};
