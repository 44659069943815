import { useRef } from "react";
import { Route, Routes } from "react-router-dom";

import MainPage from "./pages/MainPage";
import AppWrapper from "./AppWrapper";

const App = () => {
  const services = useRef(null);
  const aboutLawyer = useRef(null);
  const contacts = useRef(null);

  const scrooltoServices = () =>
    //@ts-ignore
    services.current.scrollIntoView({ behavior: "smooth" });
  const scrooltoAboutLawyer = () =>
    //@ts-ignore
    aboutLawyer.current.scrollIntoView({ behavior: "smooth" });
  const scrooltoContacts = () =>
    //@ts-ignore
    contacts.current.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <Routes>
        <Route
          element={
            <AppWrapper
              scrooltoServices={scrooltoServices}
              scrooltoAboutLawyer={scrooltoAboutLawyer}
              scrooltoContacts={scrooltoContacts}
            />
          }
        >
          <Route
            path="/"
            element={
              <MainPage
                services={services}
                aboutLawyer={aboutLawyer}
                contacts={contacts}
                scrooltoServices={scrooltoServices}
                scrooltoAboutLawyer={scrooltoAboutLawyer}
                scrooltoContacts={scrooltoContacts}
              />
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default App;
