import { Dialog } from "primereact/dialog";

import styles from "./ModalMenu.module.scss";

const ModalMenu = ({
  visible,
  hide,
  scrooltoServices,
  scrooltoAboutLawyer,
  scrooltoContacts,
}) => {
  const funcScrooltoServices = () => {
    hide();
    scrooltoServices();
  };

  const funcScrooltoAboutLawyer = () => {
    hide();
    scrooltoAboutLawyer();
  };

  const funcScrooltoContacts = () => {
    hide();
    scrooltoContacts();
  };

  return (
    //@ts-ignore
    <Dialog
      visible={visible}
      style={{ width: "100vw" }}
      onHide={hide}
      className={styles["modal"]}
      header="Меню"
    >
      <div className={styles["content"]}>
        <ul>
          <li onClick={funcScrooltoServices}>Услуги</li>
          <li onClick={funcScrooltoAboutLawyer}>Об адвокате</li>
          <li onClick={funcScrooltoContacts}>Контакты</li>
        </ul>
      </div>
    </Dialog>
  );
};

export default ModalMenu;
