export const IconAdministrativeLaw = () => {
  return (
    <svg
      id="administrative_law"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
    >
      <path d="m62.32 50.22h-4.59v-45.68a4.55 4.55 0 0 0 -4.54-4.54h-46.72a5.75 5.75 0 0 0 -5.74 5.74v8.39a1 1 0 0 0 1 .94h8.64v42.06a6.86 6.86 0 0 0 6.81 6.87h36.4a9.49 9.49 0 0 0 9.69-9.24v-3.6a1 1 0 0 0 -.95-.94zm-52-37h-7.69v-7.48a3.85 3.85 0 0 1 7.69 0zm6.89 48.88h-.05a5 5 0 0 1 -4.95-5v-52.56a.92.92 0 0 0 -.33-.7 5.7 5.7 0 0 0 -1.16-1.94h42.47a2.65 2.65 0 0 1 2.64 2.65v45.67h-32.74a1 1 0 0 0 -.95.94v6a5 5 0 0 1 -4.93 4.94zm44.16-7.34a7.58 7.58 0 0 1 -7.79 7.34h-31.68a6.81 6.81 0 0 0 2.14-5v-5h37.33z"></path>
      <path d="m34.12 42.55v-3.89a.94.94 0 0 0 -.95-.94v-1.28a3.14 3.14 0 0 0 -3.26-3h-10.63a3.15 3.15 0 0 0 -3.26 3v1.28a1 1 0 0 0 -.95.94v3.89a1 1 0 0 0 .95 1h17.15a.94.94 0 0 0 .95-1zm-16.2-6.11a1.25 1.25 0 0 1 1.36-1.1h10.63a1.26 1.26 0 0 1 1.37 1.1v1.28h-13.36zm14.31 5.17h-15.23v-2h15.23z"></path>
      <path d="m27.84 29.51a3.09 3.09 0 0 0 4.36 0l.85-.85a3.07 3.07 0 0 0 .9-2.18 3.15 3.15 0 0 0 -.1-.75l.64-.63 1.06 1.06-.18.17a1 1 0 0 0 0 1.34l12.44 12.44a3.58 3.58 0 1 0 5.06-5.06l-12.44-12.44a1 1 0 0 0 -1.34 0l-.17.18-1.06-1.06.63-.63a3.08 3.08 0 0 0 .75.1 3 3 0 0 0 2.17-.91l.86-.85a3.09 3.09 0 0 0 0-4.36l-5.57-5.56a3.13 3.13 0 0 0 -4.35 0l-.86.85a3.07 3.07 0 0 0 -.9 2.18 3.15 3.15 0 0 0 .1.75l-4.69 4.7a3.14 3.14 0 0 0 -2.9.78l-.85.86a3.05 3.05 0 0 0 0 4.35zm8-5.75.69-.69 1.06 1.06-.69.69zm15.7 12.63a1.69 1.69 0 0 1 0 2.38 1.72 1.72 0 0 1 -2.38 0l-9.76-9.77 2.39-2.39zm-11.78-11.77.69.68-2.39 2.39-.68-.69zm-6.93-12.91.86-.85a1.17 1.17 0 0 1 1.67 0l5.57 5.56a1.2 1.2 0 0 1 0 1.68l-.85.85a1.21 1.21 0 0 1 -1.68 0l-5.57-5.56a1.21 1.21 0 0 1 0-1.68zm-1.11 3.29 5.12 5.12-4 4-5.13-5.12zm-8.11 6 .86-.86a1.18 1.18 0 0 1 .84-.34 1.21 1.21 0 0 1 .84.34l5.56 5.57a1.2 1.2 0 0 1 0 1.68l-.85.85a1.2 1.2 0 0 1 -1.68 0l-5.57-5.64a1.2 1.2 0 0 1 0-1.67z"></path>
    </svg>
  );
};
