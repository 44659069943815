import styles from "./Cases.module.scss";

import { CASES_LIST } from "../../constants/cases";

const Cases = ({ services }) => {
  return (
    <div className={styles["cases-wrapper"]} ref={services}>
      <div className={styles["content"]}>
        <h1>Виды юридической деятельности</h1>
        <div className={styles["cases-list"]}>
          {CASES_LIST.map((el) => (
            <div className={styles["case"]} key={el.id}>
              {el.icon()}
              <span>{el.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cases;
