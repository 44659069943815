export const IconInheritanceLaw = () => {
  return (
    <svg viewBox="-1 0 476 476.38409" xmlns="http://www.w3.org/2000/svg">
      <path d="m469.113281 335.199219c-8.914062-15.207031-28.398437-20.425781-43.722656-11.710938l-68.125 39.3125c-5.171875-11.421875-16.535156-18.769531-29.074219-18.800781h-94.703125l-29.519531-9.839844c-12.234375-4.082031-25.046875-6.164062-37.945312-6.160156h-6.808594c-21.75 0-43.09375 5.914062-61.742188 17.105469l-11.496094 6.894531h-5.785156c0-4.417969-3.582031-8-8-8h-64c-4.417968 0-8 3.582031-8 8v112c0 4.417969 3.582032 8 8 8h64c4.417969 0 8-3.582031 8-8h60.25c2.1875 0 4.371094.128906 6.542969.382812l95.367187 11.203126c4.589844.535156 9.210938.804687 13.832032.796874 21.121094 0 41.871094-5.539062 60.183594-16.0625l141.023437-81.390624c9.953125-5.675782 16.070313-16.273438 16-27.730469-.007813-5.613281-1.484375-11.128907-4.277344-16zm-404.921875 120.800781h-48v-96h48zm385.199219-90.929688-141.023437 81.425782c-19.429688 11.152344-41.921876 15.78125-64.175782 13.214844l-95.367187-11.199219c-2.78125-.332031-5.582031-.503907-8.382813-.511719h-60.25v-80h8c1.453125 0 2.875-.394531 4.121094-1.144531l13.390625-8c16.160156-9.710938 34.660156-14.84375 53.511719-14.855469h6.808594c11.175781 0 22.277343 1.800781 32.878906 5.335938l30.761718 10.257812c.816407.269531 1.667969.40625 2.527344.40625h96c8.835938 0 16 7.164062 16 16s-7.164062 16-16 16h-120v16h120c16.15625-.050781 29.726563-12.15625 31.617188-28.199219l73.550781-42.449219c4.949219-2.855468 11.046875-2.859374 15.996094 0 4.949219 2.855469 8 8.132813 8.003906 13.847657.042969 5.722656-3.003906 11.023437-7.96875 13.871093zm0 0"></path>
      <path d="m120.191406 312h240c13.253906 0 24-10.746094 24-24s-10.746094-24-24-24v-88c-.074218-46.070312-35.652344-84.296875-81.597656-87.671875 4.222656-10.949219-.265625-23.335937-10.523438-29.039063l27.066407-47.289062c1.429687-2.476562 1.429687-5.53125-.003907-8.007812-1.433593-2.476563-4.078124-3.99609425-6.941406-3.992188h-96c-2.859375-.00390625-5.507812 1.515625-6.9375 3.992188-1.433594 2.476562-1.4375 5.53125-.007812 8.007812l27.042968 47.320312c-10.257812 5.703126-14.746093 18.089844-10.519531 29.039063-45.929687 3.386719-81.488281 41.589844-81.578125 87.640625v88c-13.253906 0-24 10.746094-24 24s10.746094 24 24 24zm96-232c0-4.417969 3.582032-8 8-8h32c4.417969 0 8 3.582031 8 8s-3.582031 8-8 8h-32c-4.417968 0-8-3.582031-8-8zm58.207032-64-22.847657 40h-22.71875l-22.847656-40zm-154.207032 264c8.835938 0 16-7.164062 16-16v-88c.042969-39.746094 32.253906-71.957031 72-72h64c39.746094.042969 71.957032 32.253906 72 72v88c0 8.835938 7.164063 16 16 16 4.417969 0 8 3.582031 8 8s-3.582031 8-8 8h-240c-4.417968 0-8-3.582031-8-8s3.582032-8 8-8zm0 0"></path>
      <path d="m240.191406 224c-4.417968 0-8-3.582031-8-8h-16c.042969 10.132812 6.445313 19.148438 16 22.527344v17.472656h16v-17.472656c11.011719-3.878906 17.621094-15.132813 15.644532-26.640625-1.980469-11.507813-11.96875-19.910157-23.644532-19.886719-4.417968 0-8-3.582031-8-8s3.582032-8 8-8c4.417969 0 8 3.582031 8 8h16c-.042968-10.132812-6.445312-19.148438-16-22.527344v-17.472656h-16v17.472656c-11.011718 3.878906-17.621094 15.132813-15.640625 26.640625 1.976563 11.507813 11.964844 19.910157 23.640625 19.886719 4.417969 0 8 3.582031 8 8s-3.582031 8-8 8zm0 0"></path>
      <path d="m280.191406 200h16v16h-16zm0 0"></path>
      <path d="m184.191406 200h16v16h-16zm0 0"></path>
    </svg>
  );
};
