import { IconEmail } from "assets/icons/email";
import { IconPhone } from "assets/icons/phone";

import styles from "./Footer.module.scss";
import IconHeaderLogo from "../../assets/img/header-logo.png";

const Footer = ({
  scrooltoServices,
  scrooltoAboutLawyer,
  scrooltoContacts,
}) => {
  return (
    <div className={styles["footer-wrapper"]}>
      <div
        className={styles["footer-logo"]}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <img src={IconHeaderLogo} />
      </div>
      <div className={styles["footer-desc"]}>
        <div className={styles["menu"]}>
          <ul>
            <li
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Главная
            </li>
            <li onClick={scrooltoServices}>Услуги</li>
            <li onClick={scrooltoAboutLawyer}>Об адвокате</li>
            <li onClick={scrooltoContacts}>Контакты</li>
          </ul>
        </div>

        <p>
          Адвокат в Таганроге. Юрист в Таганроге. Юридическая консультация в
          Таганроге. Юридическая помощь в Таганроге.
        </p>
      </div>

      <div className={styles["footer-contacts"]}>
        <div className={styles["contact-row"]}>
          <IconPhone />
          <a href="tel:+79185151440">
            <p>+7-918-515-14-40</p>
          </a>
        </div>
        <div className={styles["contact-row"]}>
          <IconEmail />
          <a href="mailto:kris.zhorova2016@yandex.ru">
            <p>kris.zhorova2016@yandex.ru</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
