import { IconEmail } from "assets/icons/email";
import { IconPhone } from "assets/icons/phone";
import { IconAdress } from "assets/icons/adress";

import styles from "./Contacts.module.scss";

const Contacts = ({ contacts }) => {
  return (
    <div className={styles["contacts-wrapper"]} ref={contacts}>
      <div className={styles["addres"]}>
        <span>
          <IconAdress />
          <p>г. Таганрог, ул. Сызранова 12, кв. 2</p>
        </span>
        <a
          href="https://yandex.ru/maps/?ll=38.862524%2C47.219192&pt=38.862324%2C47.218792&z=17"
          target="_blank"
        >
          ( показать на карте )
        </a>
      </div>
      <div className={styles["contact-row"]}>
        <IconPhone />
        <a href="tel:+79185151440">
          <p>+7-918-515-14-40</p>
        </a>
      </div>
      <div className={styles["contact-row"]}>
        <IconEmail />
        <a href="mailto:kris.zhorova2016@yandex.ru">
          <p>kris.zhorova2016@yandex.ru</p>
        </a>
      </div>
    </div>
  );
};

export default Contacts;
