import styles from "./Separate.module.scss";

const Separate = () => {
  return (
    <div className={styles["separate-wrapper"]}>
      <p>
        Какой бы ни была ваша проблема — у меня есть стратегии, как ее
        разрешить.
      </p>
    </div>
  );
};

export default Separate;
