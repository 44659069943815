export const IconFamilyLaw = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <g id="outline">
        <path d="M42,12a5,5,0,1,0,5-5A5.006,5.006,0,0,0,42,12Zm8,0a3,3,0,1,1-3-3A3,3,0,0,1,50,12Z"></path>
        <path d="M27,17a5,5,0,1,0,5-5A5.006,5.006,0,0,0,27,17Zm8,0a3,3,0,1,1-3-3A3,3,0,0,1,35,17Z"></path>
        <path d="M13,12a5,5,0,1,0,5-5A5.006,5.006,0,0,0,13,12Zm8,0a3,3,0,1,1-3-3A3,3,0,0,1,21,12Z"></path>
        <path d="M58.5,28.04A3.084,3.084,0,0,0,56,31.107v4.479l-3,3-.293-.293A3.831,3.831,0,0,0,50,37.121a3.714,3.714,0,0,0-2,.6V37h5a1,1,0,0,0,1-1V28.222a3.079,3.079,0,0,0,3.707-.517,3.124,3.124,0,0,0,0-4.412l-4-4A.994.994,0,0,0,53,19H41a.994.994,0,0,0-.708.294L35.586,24H28.362l-5.725-4.765A.993.993,0,0,0,22,19H14a.985.985,0,0,0-.692.289L7.815,24.624a3.156,3.156,0,0,0,1.447,5.49,3.21,3.21,0,0,0,.614.06,3.118,3.118,0,0,0,1.93-.681l-.8,6.383A1,1,0,0,0,12,37h5v1.537l-.293-.244a3.8,3.8,0,0,0-5.414,0L11,38.586l-3-3V31.107A3.084,3.084,0,0,0,5.5,28.04,3,3,0,0,0,2,31v7a1,1,0,0,0,.1.447l5,10a1,1,0,0,0,.314.366L14,53.515V56a1,1,0,0,0,1,1h9a1,1,0,0,0,1-1V48a5.738,5.738,0,0,0-2-4.43V37h1a1,1,0,0,0,.992-1.124l-1-7.988L27,30.461V41a2.987,2.987,0,0,0,5,2.22A2.987,2.987,0,0,0,37,41V30.414l3-3v17.3A5.486,5.486,0,0,0,39,48v8a1,1,0,0,0,1,1h9a1,1,0,0,0,1-1V53.515l6.581-4.7a1,1,0,0,0,.314-.366l5-10A1,1,0,0,0,62,38V31a3,3,0,0,0-3.5-2.96ZM54,22.414l2.293,2.293a1.12,1.12,0,0,1,0,1.586,1.145,1.145,0,0,1-1.584,0L54,25.586ZM12.255,25.9l-1.681,2.037a1.152,1.152,0,0,1-1.738-.4A1.172,1.172,0,0,1,9.163,26.1l3.491-3.4ZM13.133,35l1.75-14h6.234l1.75,14ZM21,37v4.865L19,40.2V37Zm2,18H16V53a1,1,0,0,0-.419-.813l-6.8-4.856L4,37.764V31a1,1,0,0,1,1.175-.985A1.083,1.083,0,0,1,6,31.107V36a1,1,0,0,0,.293.707l9,9,1.414-1.414L12.414,40l.293-.293a1.726,1.726,0,0,1,2.653.062l5.983,4.986A3.924,3.924,0,0,1,23,48Zm4-27.172-3.375-2.889-.318-2.548L27,25.469ZM31,41a1,1,0,0,1-2,0V34h2Zm4,0a1,1,0,0,1-2,0V34h2Zm0-9H29V26h6Zm5-7.414-3,3V25.414l3-3ZM42,21H52V35H42Zm0,16h4v2.364L42,42.7Zm18,.764-4.783,9.567-6.8,4.856A1,1,0,0,0,48,53v2H41V48a3.531,3.531,0,0,1,.775-2.33c0-.005.01-.006.013-.011a6.582,6.582,0,0,1,.861-.9l6.058-5.054a1.739,1.739,0,0,1,2.586,0l.293.293-4.293,4.293,1.414,1.414,9-9A1,1,0,0,0,58,36V31.107a1.083,1.083,0,0,1,.825-1.092A1,1,0,0,1,60,31Z"></path>
      </g>
    </svg>
  );
};
