import { IconCivilLaw } from "assets/icons/civil_law";
import { IconLaborLaw } from "assets/icons/labor_law";
import { IconFamilyLaw } from "assets/icons/family_law";
import { IconHousingLaw } from "assets/icons/housing_law";
import { IconAdministrativeLaw } from "assets/icons/administrative_law";
import { IconInheritanceLaw } from "assets/icons/inheritance_law";
import { IconLandLaw } from "assets/icons/land_law";
import { IconInsuranceLaw } from "assets/icons/insurance_law";
import { IconOtherLaw } from "assets/icons/other_low";

export const CASES_LIST = [
  {
    id: 0,
    label: "Гражданское право",
    icon: IconCivilLaw,
  },
  {
    id: 1,
    label: "Административное право",
    icon: IconAdministrativeLaw,
  },
  {
    id: 2,
    label: "Трудовое право",
    icon: IconLaborLaw,
  },
  {
    id: 3,
    label: "Жилищное право",
    icon: IconHousingLaw,
  },
  {
    id: 4,
    label: "Семейное право",
    icon: IconFamilyLaw,
  },
  {
    id: 5,
    label: "Наследственное право",
    icon: IconInheritanceLaw,
  },
  {
    id: 6,
    label: "Земельное право",
    icon: IconLandLaw,
  },
  {
    id: 7,
    label: "Страховое право",
    icon: IconInsuranceLaw,
  },
  {
    id: 8,
    label: "И другие сферы",
    icon: IconOtherLaw,
  },
];
