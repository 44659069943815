export const IconInsuranceLaw = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
    >
      <g>
        <g>
          <path d="M55,15c0.6,0,1-0.4,1-1v-4c0-0.6-0.4-1-1-1H37c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h1v2H24v-2c0-0.6-0.4-1-1-1H9 c-0.6,0-1,0.4-1,1v2H5c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h3v2c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1v-2h14v2h-1c-0.6,0-1,0.4-1,1v4 c0,0.6,0.4,1,1,1h18c0.6,0,1-0.4,1-1v-4c0-0.6-0.4-1-1-1h-1V15H55z M38,11h16v2h-1H39h-1V11z M6,21v-2h2v2H6z M22,24H10v-2v-4v-2 h3v5h2v-5h2v5h2v-5h3v2v4V24z M24,21v-2h14v2H24z M54,29H38v-2h1h14h1V29z M52,25H40v-3v-4v-3h12V25z"></path>
          <path d="M51,47H41c-0.6,0-1,0.4-1,1v10H9V29H7v30c0,0.6,0.4,1,1,1h33c0.3,0,0.5-0.1,0.7-0.3l11-11c0.1-0.1,0.1-0.2,0.2-0.3v-0.1 c0-0.1,0.1-0.2,0.1-0.3l0,0V35h-2V47z M42,49h7.6l-3.8,3.8L42,56.6V49z"></path>
          <path d="M9,2h42v3h2V1c0-0.6-0.4-1-1-1H8C7.4,0,7,0.4,7,1v8h2V2z"></path>
          <rect x="12" y="31" width="11" height="2"></rect>
          <rect x="12" y="36" width="11" height="2"></rect>
          <rect x="26" y="31" width="3" height="2"></rect>
          <rect x="26" y="36" width="3" height="2"></rect>
          <rect x="31" y="36" width="11" height="2"></rect>
          <rect x="45" y="36" width="3" height="2"></rect>
          <rect x="12" y="41" width="11" height="2"></rect>
          <rect x="26" y="41" width="3" height="2"></rect>
          <rect x="12" y="46" width="11" height="2"></rect>
          <rect x="26" y="46" width="3" height="2"></rect>
          <rect x="12" y="51" width="11" height="2"></rect>
          <rect x="26" y="51" width="3" height="2"></rect>
          <rect x="31" y="41" width="11" height="2"></rect>
          <rect x="31" y="46" width="6" height="2"></rect>
          <rect x="31" y="51" width="6" height="2"></rect>
          <rect x="45" y="41" width="3" height="2"></rect>
          <rect x="12" y="5" width="2" height="2"></rect>
          <rect x="16" y="5" width="2" height="2"></rect>
          <rect x="20" y="5" width="2" height="2"></rect>
          <rect x="24" y="5" width="2" height="2"></rect>
          <rect x="28" y="5" width="2" height="2"></rect>
        </g>
      </g>
    </svg>
  );
};
