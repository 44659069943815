export const IconIndividualApproach = () => {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="m203.929688 312.214844h-32.679688v-20.847656c18.820312-12.558594 31.25-33.980469 31.25-58.257813v-37.5c0-38.597656-31.402344-70-70-70s-70 31.402344-70 70v37.5c0 24.277344 12.429688 45.699219 31.25 58.257813v20.847656h-32.679688c-33.675781 0-61.070312 27.394531-61.070312 61.070312v138.214844h15v-64.167969h25.179688v64.167969h15v-115h-15v35.832031h-25.179688v-59.046875c0-25.402344 20.667969-46.070312 46.070312-46.070312h33.253907c2.726562 15.78125 15.019531 28.324218 30.675781 31.40625v152.878906h15v-152.878906c15.65625-3.082032 27.949219-15.625 30.675781-31.40625h33.253907c25.402343 0 46.070312 20.667968 46.070312 46.070312v59.046875h-25.179688v-35.832031h-15v115h15v-64.167969h25.179688v64.167969h15v-138.214844c0-33.675781-27.394531-61.070312-61.070312-61.070312zm-126.429688-116.609375c0-30.324219 24.671875-55 55-55s55 24.675781 55 55v7.867187l-1.96875-3.640625c-5.996094-11.085937-17.550781-17.976562-30.15625-17.976562h-45.746094c-12.605468 0-24.164062 6.890625-30.160156 17.976562l-1.96875 3.640625zm78.75 125c0 13.097656-10.652344 23.75-23.75 23.75s-23.75-10.652344-23.75-23.75v-21.652344c7.421875 2.683594 15.414062 4.152344 23.75 4.152344s16.328125-1.46875 23.75-4.152344zm-23.75-32.5c-29.71875 0-53.992188-23.695313-54.953125-53.183594l15.117187-27.953125c3.371094-6.238281 9.871094-10.113281 16.964844-10.113281h45.746094c7.089844 0 13.589844 3.875 16.960938 10.113281l15.117187 27.953125c-.960937 29.488281-25.234375 53.183594-54.953125 53.183594zm0 0"></path>
      <path d="m228 0v268h69v78.96875l100.101562-78.96875h114.898438v-268zm269 253h-105.101562l-79.898438 63.03125v-63.03125h-69v-238h254zm0 0"></path>
      <path d="m324.960938 105.5625 37.476562 37.476562-17.675781 17.679688 45.960937 45.960938 23.675782-23.675782 32.15625 32.15625 10.605468-10.605468-32.15625-32.15625.011719-.011719c14.621094-14.621094 14.621094-38.410157 0-53.035157l-76.367187-76.367187c-7.082032-7.082031-16.5-10.980469-26.515626-10.980469-10.019531 0-19.433593 3.898438-26.515624 10.980469l-34.296876 34.296875 45.960938 45.960938zm-18.738282-51.972656c4.25-4.25 9.898438-6.589844 15.910156-6.589844 6.011719 0 11.660157 2.339844 15.910157 6.589844l76.367187 76.367187c8.773438 8.773438 8.773438 23.046875 0 31.820313l-23.6875 23.6875-24.75-24.75 17.679688-17.675782-58.691406-58.6875-17.679688 17.675782-24.746094-24.75zm0 0"></path>
      <path d="m457.152344 225.765625 10.609375-10.605469 10.605469 10.605469-10.605469 10.609375zm0 0"></path>
      <path d="m30 373.285156h15c0-8.859375 7.210938-16.070312 16.070312-16.070312v-15c-17.132812 0-31.070312 13.9375-31.070312 31.070312zm0 0"></path>
      <path d="m467 77.5h15v-47.5h-47.5v15h32.5zm0 0"></path>
      <path d="m305.5 223h-32.5v-32.5h-15v47.5h47.5zm0 0"></path>
    </svg>
  );
};
