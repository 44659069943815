import styles from "./AboutLaw.module.scss";

import FemidaImg from "../../assets/img/femida.png";

const AboutLaw = ({ aboutLawyer }) => {
  return (
    <div className={styles["about-law-wrapper"]} ref={aboutLawyer}>
      <h1>Об адвокате</h1>
      <div className={styles["content"]}>
        <div className={styles["image-container"]}></div>
        <div className={styles["info-card"]}>
          <span>Персональные данные</span>
          <p>Жорова Кристина Владимировна, 1973г.</p>
          <span>Образование</span>
          <p>
            Высшее юридическое образование. Специализация: юриспруденция.
            Квалификация: юрист.
          </p>
          <span>Контактные данные</span>
          <p>Мобильный тел.: +7-918-515-14-40</p>
          <p>E-mail: kris.zhorova2016@yandex.ru</p>
          <div className={styles["femida"]}>
            <img src={FemidaImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutLaw;
