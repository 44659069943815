export const IconConfidentiality = () => {
  return (
    <svg
      version="1.1"
      id="confidentiality"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512.001 512.001"
    >
      <g>
        <g>
          <path d="M457.659,208.459c1.264-7.267,1.901-14.649,1.901-22.046c0-70.657-57.563-128.141-128.317-128.141 c-26.15,0-51.311,7.81-72.763,22.584c-17.033,11.731-30.828,27.184-40.452,45.19c-17.498-13.809-38.934-21.28-61.652-21.28 c-55.049,0-99.834,44.725-99.834,99.699c0,1.001,0.017,2.012,0.049,3.029c-15.135,6.255-28.593,16.67-38.459,29.889 C6.269,253.275,0,272.161,0,292.001c0,1.337,0.052,2.483,0.102,3.591l0.016,0.359c2.184,48.802,42.235,87.029,91.179,87.029 h60.782v16.296c0,29.008,23.604,54.453,50.514,54.453h106.814c27.854,0,50.514-22.633,50.514-50.453v-20.295h60.782 c48.943,0,88.994-38.228,91.179-87.029l0.016-0.359c0.051-1.107,0.103-2.254,0.103-3.591 C512,255.472,490.499,222.942,457.659,208.459z M152.079,362.981H91.297c-34.645,0-63.585-24.52-69.964-57.477h130.746V362.981z M162.196,230.419v55.085h-0.116H20.303c2.644-28.308,22.361-53.114,49.76-61.635c4.569-1.421,7.492-5.881,6.971-10.638 c-0.326-2.977-0.491-5.927-0.491-8.766c0-43.946,35.813-79.699,79.834-79.699c22.011,0,42.519,8.774,57.747,24.704 c2.35,2.459,5.783,3.555,9.126,2.909c3.339-0.646,6.121-2.943,7.386-6.1c16.55-41.313,56.041-68.008,100.608-68.008 c59.727,0,108.317,48.512,108.317,108.141c0,0.234-0.009,0.469-0.011,0.703H304.768H205.55h-51.2c-5.522,0-10,4.477-10,10 c0,5.522,4.478,10,10,10h14.688C164.715,213.848,162.196,221.843,162.196,230.419z M328.121,230.42v55.085H182.195V230.42 c0-12.85,10.477-23.304,23.355-23.304h99.218C317.645,207.116,328.121,217.57,328.121,230.42z M339.921,372.981v30.296 c0,16.792-13.689,30.453-30.514,30.453H202.593c-17.464,0-30.514-18.189-30.514-34.453v-26.296v-67.477h167.842V372.981z M420.703,362.981h-60.782v-57.477h130.746C484.288,338.462,455.349,362.981,420.703,362.981z M349.921,285.504h-1.8v-55.085 c0-8.576-2.519-16.57-6.842-23.304h96.28c-0.34,1.751-0.712,3.495-1.138,5.225c-1.25,5.076,1.62,10.259,6.587,11.892 c27.23,8.952,46.158,33.104,48.7,61.272H349.921z"></path>
        </g>
      </g>
      <g>
        <g>
          <path d="M123.09,195.161c-0.13-0.64-0.32-1.27-0.57-1.87c-0.25-0.6-0.56-1.19-0.92-1.73c-0.359-0.55-0.78-1.06-1.24-1.52 c-0.46-0.46-0.97-0.88-1.52-1.24c-0.54-0.36-1.12-0.67-1.73-0.92c-0.6-0.25-1.229-0.44-1.869-0.57c-1.29-0.26-2.61-0.26-3.91,0 c-0.63,0.13-1.261,0.32-1.87,0.57c-0.601,0.25-1.18,0.56-1.72,0.92c-0.551,0.37-1.06,0.78-1.52,1.24 c-0.46,0.46-0.88,0.98-1.24,1.52c-0.37,0.55-0.67,1.13-0.93,1.73c-0.25,0.6-0.44,1.23-0.57,1.87 c-0.13,0.649-0.189,1.3-0.189,1.949c0,0.66,0.06,1.311,0.189,1.96c0.13,0.641,0.32,1.261,0.57,1.87c0.25,0.6,0.56,1.181,0.93,1.73 c0.36,0.54,0.78,1.06,1.24,1.52c0.46,0.46,0.97,0.88,1.52,1.24c0.54,0.36,1.119,0.67,1.72,0.92c0.609,0.25,1.24,0.44,1.87,0.57 c0.649,0.13,1.31,0.189,1.96,0.189c0.649,0,1.31-0.06,1.95-0.189c0.64-0.13,1.27-0.32,1.869-0.57c0.61-0.25,1.19-0.56,1.73-0.92 c0.55-0.36,1.06-0.78,1.52-1.24c0.46-0.46,0.881-0.98,1.24-1.52c0.36-0.55,0.67-1.13,0.92-1.73c0.25-0.6,0.44-1.23,0.57-1.87 c0.13-0.649,0.2-1.3,0.2-1.96C123.29,196.461,123.22,195.8,123.09,195.161z"></path>
        </g>
      </g>
      <g>
        <g>
          <path d="M255.875,346.429c-5.523,0-10,4.478-10,10v25.698c0,5.522,4.477,10,10,10c5.522,0,10-4.478,10-10v-25.698 C265.875,350.907,261.397,346.429,255.875,346.429z"></path>
        </g>
      </g>
    </svg>
  );
};
