import { Outlet } from "react-router-dom";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";

import Header from "./components/Header/Header";

const AppWrapper = ({
  scrooltoServices,
  scrooltoAboutLawyer,
  scrooltoContacts,
}) => {
  return (
    <div>
      <Header
        scrooltoServices={scrooltoServices}
        scrooltoAboutLawyer={scrooltoAboutLawyer}
        scrooltoContacts={scrooltoContacts}
      />
      <Outlet />
      <ScrollUpButton
        ContainerClassName={"scroll-up-btn"}
        AnimationDuration={750}
      />
    </div>
  );
};

export default AppWrapper;
