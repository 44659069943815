export const IconPhone = () => {
  return (
    <svg
      id="phone"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m250.559 386.779c29.218-14.312 59.12-35.307 79.983-56.19 20.468-20.467 41.968-51.049 56.096-79.78 12.779-25.986 26.18-63.61 17.411-95.267-6.972-25.161-26.137-41.831-56.978-49.553-11.491-2.877-23.18 2.08-29.1 12.33l-27.642 47.879c-6.014 10.42-4.34 23.152 4.17 31.661l15.001 15c3.123 3.118 3.91 7.382 2.099 11.411-18.17 40.628-46.751 69.209-87.38 87.379-4.023 1.811-8.292 1.019-11.411-2.099l-14.996-15c-8.519-8.51-21.241-10.189-31.666-4.17l-47.874 27.638c-10.255 5.925-15.213 17.614-12.335 29.105 1.297 5.156 2.849 10 4.646 14.496-15.057 17.802-29.157 24.543-41.93 20.024-13.859-4.901-27.237-22.552-37.666-49.723-9.241-24.067-14.986-53.162-14.986-75.922 0-132.338 107.663-239.997 240.002-239.997s239.997 107.659 239.997 239.997c0 132.343-107.658 240.002-239.997 240.002-54.006 0-105.008-17.51-147.48-50.648-3.486-2.712-8.514-2.094-11.231 1.387-2.717 3.491-2.095 8.51 1.387 11.231 45.316 35.35 99.713 54.03 157.323 54.03 68.379 0 132.669-26.628 181.019-74.979 48.351-48.35 74.979-112.64 74.979-181.023 0-68.379-26.628-132.669-74.978-181.019-48.351-48.351-112.64-74.979-181.02-74.979-68.383 0-132.668 26.628-181.019 74.979-48.355 48.35-74.983 112.64-74.983 181.019 0 24.59 6.151 55.883 16.052 81.662 8.585 22.359 23.755 50.751 47.265 59.068 12.812 4.533 32.345 4.311 55.096-19.859 8.976 13.694 21.378 22.784 37.09 27.133 7.099 1.958 14.486 2.816 21.982 2.816 25.893 0 52.979-10.199 73.074-20.039z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};
